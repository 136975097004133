import { createBrowserRouter } from 'react-router-dom';
import HomeLayout from '../layouts/home.layout';
import RequiredMissionPage from '../pages/required-mission';
import ProtectedRoute from './protected.route';
import LandingPage from '../pages/landing';
import { lazy } from 'react';
import IDOListPage from '../pages/ido-list';

// lazy load routes
const CampaignPage = lazy(() => import('../pages/campaign'));
const EarnPage = lazy(() => import('../pages/earn'));
const FarmPage = lazy(() => import('../pages/farm'));
const LuckySpinPage = lazy(() => import('../pages/lucky-spin'));
const MissionPage = lazy(() => import('../pages/mission'));
const MyRewardsPage = lazy(() => import('../pages/my-rewards'));
const PackagesPage = lazy(() => import('../pages/packages'));
const RankingPage = lazy(() => import('../pages/ranking'));
const ReferralPage = lazy(() => import('../pages/referral'));
const WalletPage = lazy(() => import('../pages/wallet'));
const WithdrawalPage = lazy(() => import('../pages/withdrawal'));
const WalletHistoryPage = lazy(() => import('../pages/wallet-history'));
const CampaignsPage = lazy(() => import('../pages/campaigns'));
const TransactionDetailPage = lazy(() => import('../pages/transaction-detail'));
const LotteryPools = lazy(() => import('../pages/lottery'));
const LotteryPoolDetail = lazy(() => import('../pages/lottery-detail'));

export const ROUTES = {
	ERROR: '*',
	HOME: '/',
	PACKAGES: '/packages',
	MISSION: '/mission',
	RANKING: '/ranking',
	REFERRAL: '/referral',
	FRIENDS: '/friends',
	WALLET: '/wallet',
	MY_REWARDS: '/my-rewards',
	LUCKY_SPIN: '/lucky-spin',
	EARN: '/earn',
	FARM: '/farm',
	REQUIRED_MISSION: '/required-mission',
	CAMPAIGN: '/campaign',
	WITHDRAWAL: '/withdrawal',
	WALLET_HISTORY: '/wallet-history',
	CAMPAIGNS: '/campaigns',
	TRANSACTION_DETAIL: '/transaction-detail',
	IDO_LIST: '/ido-list',
	LOTTERY: '/lottery',
	LOTTERY_DETAIL: '/lottery/pool-detail',
};

export const router = createBrowserRouter([
	{
		element: <HomeLayout />,
		children: [
			{
				path: ROUTES.HOME,
				element: (
					<ProtectedRoute>
						<LandingPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.PACKAGES,
				element: (
					<ProtectedRoute>
						<PackagesPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.MISSION,
				element: (
					<ProtectedRoute>
						<MissionPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.RANKING,
				element: (
					<ProtectedRoute>
						<RankingPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.EARN,
				element: (
					<ProtectedRoute>
						<EarnPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.REFERRAL,
				element: (
					<ProtectedRoute>
						<ReferralPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.WALLET,
				element: (
					<ProtectedRoute>
						<WalletPage />
					</ProtectedRoute>
				),
			},

			{
				path: ROUTES.WITHDRAWAL,
				element: (
					<ProtectedRoute>
						<WithdrawalPage />
					</ProtectedRoute>
				),
			},

			{
				path: ROUTES.WALLET_HISTORY,
				element: (
					<ProtectedRoute>
						<WalletHistoryPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.MY_REWARDS,
				element: (
					<ProtectedRoute>
						<MyRewardsPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.LUCKY_SPIN,
				element: (
					<ProtectedRoute>
						<LuckySpinPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.FARM,
				element: (
					<ProtectedRoute>
						<FarmPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.REQUIRED_MISSION,
				element: (
					<ProtectedRoute>
						<RequiredMissionPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.CAMPAIGN,
				element: (
					<ProtectedRoute>
						<CampaignPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.CAMPAIGNS,
				element: (
					<ProtectedRoute>
						<CampaignsPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.TRANSACTION_DETAIL,
				element: (
					<ProtectedRoute>
						<TransactionDetailPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.IDO_LIST,
				element: (
					<ProtectedRoute>
						<IDOListPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.LOTTERY,
				element: (
					<ProtectedRoute>
						<LotteryPools />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.LOTTERY_DETAIL,
				element: (
					<ProtectedRoute>
						<LotteryPoolDetail />
					</ProtectedRoute>
				),
			},
		],
	},
]);
