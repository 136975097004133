import { Suspense, useEffect } from 'react';
import {
	Outlet,
	ScrollRestoration,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import TelegramLogin from '../components/Home/TelegramLogin';
import Header from '../components/Layout/Header';
import Tabbar from '../components/UI/Tabbar';
import { setCurrencies } from '../redux/reducers/wallet.slice';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { ROUTES } from '../routes';
import walletRequest from '../service/wallet.request';
import LoadingLayout from './loading.layout';
import ProviderOKXWallet from '../components/Home/ProviderOKXWallet';
import { EntryRequirementRequest } from '../service/entry-requirement.request';
import { MISSION_STATUS } from '../constants/mission';
import { setLoading } from '../redux/reducers/user.slice';
import { IS_PASSED_REQUIREMENT } from '../constants';

export default function HomeLayout() {
	const location = useLocation();
	const navigate = useNavigate();
	const isLoading = useAppSelector((state: RootState) => state.user.isLoading);

	const dispatch = useAppDispatch();
	// const [notifications, setNotifications] = useState<INotification[]>([]);
	const user = useAppSelector((state: RootState) => state.user);
	const balance = useAppSelector(
		(state: RootState) => state.loyaltyWallet.balance
	);
	const NONE_MENU_PAGE = [
		ROUTES.PACKAGES,
		ROUTES.LUCKY_SPIN,
		ROUTES.FARM,
		ROUTES.REQUIRED_MISSION,
		ROUTES.WALLET,
		ROUTES.WITHDRAWAL,
		ROUTES.WALLET_HISTORY,
		ROUTES.CAMPAIGN,
		ROUTES.CAMPAIGNS,
		ROUTES.TRANSACTION_DETAIL,
		ROUTES.IDO_LIST,
		ROUTES.LOTTERY,
		ROUTES.LOTTERY_DETAIL,
	];
	const BACKPAGES = [
		ROUTES.PACKAGES,
		ROUTES.LUCKY_SPIN,
		ROUTES.FARM,
		ROUTES.CAMPAIGN,
		ROUTES.WALLET,
		ROUTES.WALLET_HISTORY,
		ROUTES.WITHDRAWAL,
		ROUTES.CAMPAIGN,
		ROUTES.CAMPAIGNS,
		ROUTES.TRANSACTION_DETAIL,
		ROUTES.IDO_LIST,
		ROUTES.LOTTERY,
		ROUTES.LOTTERY_DETAIL,
	];

	const PARENT_ROUTES = [
		ROUTES.EARN,
		ROUTES.HOME,
		ROUTES.REFERRAL,
		ROUTES.MISSION,
		ROUTES.RANKING,
	];

	const NONE_HEADER_PAGES = [
		ROUTES.PACKAGES,
		ROUTES.WALLET,
		ROUTES.WITHDRAWAL,
		ROUTES.WALLET_HISTORY,
		ROUTES.CAMPAIGNS,
		ROUTES.CAMPAIGN,
		ROUTES.TRANSACTION_DETAIL,
		ROUTES.REQUIRED_MISSION,
		ROUTES.IDO_LIST,
	];

	useEffect(() => {
		if (BACKPAGES.includes(location.pathname)) {
			window?.Telegram?.WebApp?.BackButton?.show();
			window?.Telegram?.WebApp?.BackButton?.onClick(() => {
				navigate(-1);
			});
		} else {
			window?.Telegram?.WebApp?.BackButton?.hide();
		}
	}, [location.pathname]);

	useEffect(() => {
		getCurrencies();
	}, []);

	useEffect(() => {
		if (!user?.id) return;
		fetchEntryRequirements(user.id);
	}, [user?.id]);

	const fetchEntryRequirements = async (userId: string) => {
		try {
			const isPassedRequirement = localStorage.getItem(IS_PASSED_REQUIREMENT);
			if (isPassedRequirement === userId) {
				return navigate(`${location.pathname}${location.search}`);
			}
			const req = new EntryRequirementRequest();
			const res = await req.getRequirements();
			if (res) {
				const successMissions = res.data?.data?.filter(
					(mission: any) => mission.status == MISSION_STATUS.SUCCESS
				);
				if (successMissions.length < 2) {
					navigate(ROUTES.REQUIRED_MISSION);
				} else {
					localStorage.setItem(IS_PASSED_REQUIREMENT, user.id);
					navigate(`${location.pathname}${location.search}`);
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const getCurrencies = async () => {
		try {
			const res = await walletRequest.getCurrencies();
			dispatch(setCurrencies(res.data.data));
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			{isLoading && <LoadingLayout />}
			<TelegramLogin />
			<ProviderOKXWallet />
			<Wrapper
				style={
					location.pathname === ROUTES.FARM ? { background: '#060C16' } : {}
				}
				isNoneHeaderPage={NONE_HEADER_PAGES.includes(location.pathname)}
			>
				{NONE_HEADER_PAGES.includes(location.pathname) ? null : (
					<Header
						isDecoration={PARENT_ROUTES.includes(location.pathname)}
						balance={balance}
					/>
				)}
				<Body hasNavigation={!NONE_MENU_PAGE.includes(location.pathname)}>
					{!!user?.id && (
						<>
							<Suspense fallback={<div />}>
								<Outlet />
							</Suspense>
							<ScrollRestoration
								getKey={(location) => {
									const paths = [ROUTES.HOME];
									return paths.includes(location.pathname)
										? location.pathname
										: location.key;
								}}
							/>
						</>
					)}
				</Body>
				{NONE_MENU_PAGE.includes(location.pathname) ? null : <Tabbar />}
			</Wrapper>
		</>
	);
}

const Wrapper = styled.div<{ isNoneHeaderPage: boolean }>`
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	background: #111113;
	position: relative;
	box-sizing: border-box;
	padding-top: ${(props) => (props.isNoneHeaderPage ? '0' : '60px')};
`;

const Body = styled.div<{ hasNavigation: boolean }>`
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding-bottom: ${(props) => (props.hasNavigation ? '80px' : '0')};
	overflow-x: hidden;
	box-sizing: border-box;
`;
