import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN } from '../../constants';
import { getLoyaltyWalletReq } from '../../redux/actions/loyalty-wallet.action';
import { fetchReferralProfile } from '../../redux/reducers/referral.slice';
import { setLoading, updateUser } from '../../redux/reducers/user.slice';
import { useAppDispatch } from '../../redux/store';
import authRequest from '../../service/authRequest';
import { UserRequest } from '../../service/user.request';
import { toast } from 'react-toastify';
import { getWalletReq } from '../../redux/actions/wallet.action';

enum START_PARAM {
	VIEW = 'v',
}
const TelegramLogin = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userRequest = new UserRequest();

	const signIn = async (initData: string) => {
		let count = 0;
		while (count < 3) {
			try {
				const res = await authRequest.signIn({
					initData,
				});
				localStorage.setItem(ACCESS_TOKEN, res.data.data.accessToken);
				dispatch(updateUser(res.data.data));
				dispatch(getLoyaltyWalletReq());
				dispatch(fetchReferralProfile());
				dispatch(getWalletReq());
				getUserAccount();
				break;
			} catch (error) {
				console.log(error);
				count++;
			}
		}
		if (count === 3) {
			const msg = 'There was a problem with the network. Please try again!';
			toast.error(msg, {
				position: 'top-right',
				autoClose: 5000,
			});
		}
	};

	const getUserAccount = async () => {
		try {
			const res = await userRequest.getMe();
			dispatch(updateUser(res.data.data));
		} catch (error) {
			console.log(error);
		} finally {
			dispatch(setLoading(false));
		}
	};
	const handleRedirect = (param: string | undefined) => {
		if (!param) return;
		const paramList = param.split('-');
		paramList.forEach((param) => {
			const params = param.split('_');
			if (params[0] === START_PARAM.VIEW) {
				navigate(`/${params[1]}`);
			}
		});
	};
	useEffect(() => {
		const tg = window.Telegram.WebApp;
		signIn(tg.initData);
		handleRedirect(tg.initDataUnsafe.start_param);
	}, []);

	return <></>;
};

export default TelegramLogin;
